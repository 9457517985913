* {
  user-select: none;
}

/* PHONE INPUT ------------------------------------------------------------------------------- */
.PhoneInput {
  transition-duration: 0.15s;
  background: transparent;
  border: 1px solid rgba(209, 213, 219);
  width: 100%;
  height: 44px;
  border-radius: 6px;
  padding-left: 12px;
}

.dark-mode {
  background-color: transparent;
  border: 1px solid rgba(75, 85, 99);
  width: 100%;
  height: 44px;
  border-radius: 6px;
  padding-left: 12px;
  color: rgba(243, 244, 246);
}

/* .special-label {
    display: none;
} */

.PhoneInput--focus {
  --tw-border-opacity: 1;
  border-color: red;
}

.PhoneInput .PhoneInputInput {
  height: 44px;
}

.PhoneInput .PhoneInputCountryIcon {
  width: 2.3rem !important;
  height: 1.6rem !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.PhoneInput .PhoneInputCountryIcon--border {
  background-color: transparent;
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInput .PhoneInputCountrySelectArrow {
  width: 0.5rem !important;
  height: 0.5rem !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.PhoneInput .PhoneInputCountry {
  --tw-border-opacity: 1;
  appearance: none;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  padding: 0.5rem 0.3rem;
  transition-duration: 0.15s;
  background: transparent;
}

.dark-mode .PhoneInputCountry {
  --tw-border-opacity: 1;
  appearance: none;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  padding: 0.5rem 0.3rem;
  transition-duration: 0.15s;
  background: transparent;
}

.currencyInput {
  transition-duration: 0.15s;
  background: transparent;
  border: 1px solid rgba(209, 213, 219);
  width: 100%;
  height: 44px;
  border-radius: 6px;
  padding-left: 12px;
}

.darkDisabledField {
  background-color: rgba(55, 65, 81);
}

.lightDisabledField {
  background-color: rgba(243, 244, 246);
}
